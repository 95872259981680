import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { apiRoutes, externalLinksConfigs } from '../../config/apiConfig';

const Footer = () => {
  return (
    <>
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-md-4 footer-desc">
            <div>
              <div className="footer-img">
                <a href={apiRoutes.homecrew}>
                  <LazyLoadImage  className="footer-logo" src="/static/crew/assets/img/brands/newLogo.png" alt="Logo" />
                </a>
              </div>
              <div className="footer-para">
                <h1 style={{ fontSize: '14px' }}>Join My Ship revolutionizes maritime job searches, connecting seafarers with reputable shipping companies. Find Shipping Jobs in India, Deck Officer Jobs in India, Cruise Ship Job Openings, and Merchant Navy Officer Recruitment. We offer Entry-level Seafarer Jobs, Offshore Oil Rig Jobs, Marine Electrician Jobs, and High-paying Maritime Jobs.</h1>
              </div>
            </div>
          </div>
          <div className="col-md-4 footer-contact">
            <div>
              <h1 className="contact-us" style={{ fontSize: '16px', color: '#407bff', fontWeight: 'bold' }}>Contact Us</h1>
            </div>
            <div className="contact-us-container">
              <span className="contact-us-img">
                <LazyLoadImage  src="/static/crew/assets/img/icons/Mail_blue.svg" alt="Mail" />
              </span>
              <h1 className="contact-us-mail" style={{ fontSize: '16px' }}>support@joinmyship.com</h1>
            </div>
            <div className="contact-us-social-media">
              <a href={externalLinksConfigs.linkedInLink} target='_blank' ><LazyLoadImage  className="social-media-linkedin" src="/static/crew/assets/img/social-media-icons/Linkedin.svg" alt="LinkedIn" /></a>
              <a href={externalLinksConfigs.instagramLink}><LazyLoadImage  className="social-media-instagram" src="/static/crew/assets/img/social-media-icons/instagram.svg" alt="Instagram" /></a>
              <a href={externalLinksConfigs.facebookLink}><LazyLoadImage  className="social-media-facebook" src="/static/crew/assets/img/social-media-icons/Facebook.svg" alt="Facebook" /></a>
            </div>
          </div>
          <div className="col-md-4 footer-quick-links">
            <h1 className="quick-links-heading" style={{ fontSize: '16px', color: '#407bff', fontWeight: 'bold' }}>Quick Links</h1>
            <ul className="list-group quick-links-list" style={{ background: 'var(--bs-body-bg)', borderColor: 'var(--bs-body-bg)', borderRadius: '0px' }}>
              <li className="list-group-item"><a href={apiRoutes.crew_blogs}><span>Blogs</span></a></li>
              <li className="list-group-item"><a href={apiRoutes.crew_help_support}><span>Contact Us</span></a></li>
              <li className="list-group-item"><a href={apiRoutes.privacy_policies}><span>Privacy Policy</span></a></li>
              <li className="list-group-item"><a href={apiRoutes.terms_and_conditions}><span>Terms and conditions</span></a></li>
              <li className="list-group-item"><a href={apiRoutes.cancellation_and_refund}><span>Cancellation and refund policy</span></a></li>
            </ul>
          </div>
        </div>
        <div className="footer-copyright">
          <p className="footer-copyright-text">
            Copyright © 2024 Join My Ship | All rights reserved.
          </p>
        </div>
      </div>
    </footer>
    <div className="footer-waves" style={{ background: 'url("/static/crew/assets/img/hero/Vector6.svg") 0px / auto repeat', marginTop: '-24px' }}>
      <LazyLoadImage  src="/static/crew/assets/img/hero/Vector6.svg" alt="Vector 6" />
    </div>
    </>
  );
};

export default Footer;
